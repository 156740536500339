@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");


*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: #635bff33;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: #635BFF;
  border-radius: 14px;
  border: 3px solid var(--primary);
}